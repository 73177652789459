import * as React from 'react';
import { graphql } from 'gatsby';
import Default from '../../layouts/default';
import SEO from 'components/seo';
import { richTextPageHelper } from 'core/pageHelpers/richTextHelper';
import { renderRichText } from 'core/renderRichText';

const Terms: React.FC<{ data: any }> = ({ data }) => {
  const pageData: GatsbyTypes.ContentfulPage = data.terms;
  const page = pageData.edges[0].node;
  const blocks = page.pageBlocks ? Object.values(richTextPageHelper(page.pageBlocks)) : null;
  const seo = page.seo;
  const richText = page.text;

  return (
    <>
      <Default>
        <SEO data={seo} />
        {blocks && blocks.map((block) => block)}
        <div className="container py-5">
          <div className="row">
            <div className="col-12">{renderRichText(richText)}</div>
          </div>
        </div>
      </Default>
    </>
  );
};

export const query = graphql`
  {
    terms: allContentfulPage(filter: { page: { eq: "Terms" } }) {
      edges {
        node {
          seo {
            ...ContentfulSeo
          }
          text {
            raw
          }
          pageBlocks {
            type: __typename
            ... on Node {
              ... on ContentfulComponentPageHeader {
                ...ContentfulComponentPageHeader
              }
            }
          }
        }
      }
    }
  }
`;

export default Terms;
